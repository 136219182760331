<template>
  <ElFormItem
    :prop="field"
    :error="errors[field]"
  >
    <ElCheckbox v-model="model[field]">
      {{ locale.label }}
    </ElCheckbox>
  </ElFormItem>
</template>

<script>
import ElCheckbox from 'element/ElCheckbox'
import ElFormItem from 'element/ElFormItem'

export default {
  components: {
    ElFormItem,
    ElCheckbox,
  },

  data: () => ({
    field: 'qr_code',
  }),

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('form.data.fields.qrCode.label'),
    }),
    errors: ({ $store }) => $store.getters['creative/errors'],
  },
}
</script>
