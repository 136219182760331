<template>
  <DragNDropTemplate
    :options="options"
    @change="$emit('change', $event)"
    @error="$emit('error', $event)"
  />
</template>

<script>
import DragNDropTemplate from './DragNDropTemplate'

export default {
  components: {
    DragNDropTemplate,
  },

  computed: {
    title: ({ $locale }) => $locale('form.files.instructions.dragImage'),
    options: ({ title }) => ({
      options: {
        title,
        icon: {
          name: 'creative-image',
          width: '36px',
          height: '36px',
        },
        maxSizeMb: 1,
        displayMaxSizeMb: 1,
        accept: 'image',
      },
    }),
  },
}
</script>
