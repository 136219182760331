<template>
  <DashboardTitle class="creative-header">
    <div class="headline-2 creative-header__name">
      {{ title }}
    </div>
  </DashboardTitle>
</template>

<script>
import DashboardTitle from 'components/layouts/DashboardLayout/components/DashboardTitle'

export default {
  components: {
    DashboardTitle,
  },

  props: {
    loaded: VueTypes.bool,
  },

  computed: {
    locale: ({ $locale }) => ({
      newCreative: $locale('defaultNameNew'),
      loading: $locale('loading'),
    }),
    creative: ({ $store }) => $store.getters['creative/creative'],
    title ({ creative, loaded, $route, locale }) {
      if (!loaded) return locale.loading
      if ($route.name === 'edit-creative') return creative.title || 'Creative ' + creative.slug
      return locale.newCreative
    },
  },
}
</script>
