<template>
  <div class="advice-manager">
    <ShowPreview v-if="hasAttachment" />
    <Advice
      v-else
      :title="locale.title"
    >
      <p v-html="requirement" />
    </Advice>
  </div>
</template>

<script>
import Advice from 'components/Advice'

import ShowPreview from './ShowPreview'

export default {
  components: {
    ShowPreview,
    Advice,
  },

  computed: {
    locale: ({ $locale }) => $locale('form.files.requirements'),
    format: ({ $store }) => $store.getters['creative/format'],
    attachments: ({ $store }) => $store.getters['creative/creative']?._?.attachments,
    hasAttachment: ({ attachments }) => Object.keys(attachments).find(key => attachments[key] !== null),
    requirement: ({ format, locale }) => {
      if (format === 'pip_video') return locale.pip_video
      if (format === 'video') return locale.fullscreen
      if (format === 'admngr') return locale.fullscreen
      if (format === 'custom') return locale.custom
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.advice-manager {
  position: absolute;
  left: calc(100% + 24px);
  top: 64px;
}
</style>
