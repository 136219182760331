<template>
  <div class="creative-manager">
    <div
      v-if="title"
      class="text-m-bold creative-manager__title"
    >
      {{ title }}
    </div>

    <component
      v-if="step === steps.TAKING && DragNDrop"
      :is="DragNDrop"
      @change="onChange"
      @error="onError"
    />

    <CreativeUploader
      v-if="step === steps.UPLOADING"
      :url="uploadingUrl"
      :params="endpointParams"
      :uploading="uploading"
      :has-errors="!!errors.length"
      @uploaded="onUploaded"
      @error="onError"
    />

    <template v-if="step === steps.DISPLAYING">
      <FilePreview
        :type="type"
        :attachment="attachments[type]"
        @delete="onDelete"
      />
    </template>

    <div
      v-if="errors.length"
      class="creative-manager__errors"
    >
      <div
        v-for="item in errors"
        :key="item"
        class="creative-manager__errors-item"
        v-html="item"
      />
    </div>
  </div>
</template>

<script>
import CreativeUploader from 'components/CreativeUploader'
import FilePreview from 'components/FilePreview'

import DragNDropImage from './DragNDrops/DragNDropImage'
import DragNDropUnit from './DragNDrops/DragNDropUnit'
import DragNDropVideo from './DragNDrops/DragNDropVideo'
import DragNDropZip from './DragNDrops/DragNDropZip'

const steps = {
  TAKING: 'TAKING',
  UPLOADING: 'UPLOADING',
  DISPLAYING: 'DISPLAYING',
}

export default {
  components: {
    CreativeUploader,
    FilePreview,
  },

  props: {
    title: VueTypes.string,
    type: VueTypes.string.isRequired,
  },

  data: () => ({
    steps,
    file: null,
    uploading: false,
    errors: [],
  }),

  computed: {
    locale: ({ $locale }) => ({
      unknownError: $locale('form.creativeManager.errors.unknown'),
      deleted: $locale('form.creativeManager.messages.deleted'),
    }),
    format: ({ $store }) => $store.getters['creative/format'],
    attachments: ({ $store }) => $store.getters['creative/attachments'],
    endpointParams ({ $route, type, file }) {
      return {
        slug: $route.params.creativeSlug,
        [type]: file,
      }
    },
    uploadingUrl ({ format }) {
      return `${process.env.VUE_APP_API_URL}partner/campaigns/brand_awareness/ad/${format}`
    },
    DragNDrop ({ type }) {
      if (type === 'video') return DragNDropVideo
      if (type === 'admngr') return DragNDropUnit
      if (type === 'image') return DragNDropImage
      if (type === 'unit') return DragNDropUnit
      if (type === 'zip') return DragNDropZip
      return null
    },
    step ({ attachments, type, uploading }) {
      if (attachments[type]) return steps.DISPLAYING
      if (uploading) return steps.UPLOADING
      return steps.TAKING
    },
  },

  methods: {
    onChange (files) {
      this.file = files[0]
      this.uploading = true
      this.errors = []
      this.$store.dispatch('creative/errorAttachment', this.errors)
    },
    onUploaded (attachment) {
      this.$store.dispatch('creative/addAttachment', attachment)
      this.uploading = false
    },
    onDelete (type, locale) {
      this.$store.dispatch('creative/deleteAttachment', type, locale)
      this.errors = []
    },
    onError (errors) {
      this.errors = []
      errors.forEach(error => {
        if (typeof error === 'string') {
          this.errors.push(error)
        }
        else if ('text' in error) {
          this.errors.push(error.text)
        }
        else {
          this.errors.push(this.locale.unknownError)
        }
      })
      this.$store.dispatch('creative/errorAttachment', this.errors)
    },
  },
}
</script>

<style lang="scss" scoped>
.creative-manager {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__errors {
    margin-top: 15px;

    &-item {
      color: $--color-danger;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
