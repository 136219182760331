<template>
  <DashboardSection
    class="creative-actions"
    no-left
  >
    <div class="creative-actions__inner">
      <div class="creative-actions__inner-left">
        <ElButton
          type="primary"
          plain
          @click="onBack"
        >
          {{ $t('button.back.static') }}
        </ElButton>
      </div>
      <div class="creative-actions__inner-right">
        <ElButton
          type="primary"
          :loading="pending"
          @click="onSubmit"
        >
          {{ $t('button.save') }}
        </ElButton>
      </div>
    </div>
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElButton from 'element/ElButton'

export default {
  components: {
    DashboardSection,
    ElButton,
  },

  computed: {
    pending: ({ $store }) => $store.getters['creative/pending'],
    isEdit: ({ $route }) => $route.name === 'edit-creative',
    locale: ({ $rootLocale }) => $rootLocale('views.partner.creative'),
  },

  methods: {
    onBack () {
      this.$router.go(-1)
    },
    onSubmit () {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isEdit ? this.update() : this.create()
    },
    async create () {
      const hasAttachment = await this.$store.dispatch('creative/checkAttachments', this.locale)

      if (!hasAttachment) return

      const res = await this.$store.dispatch('creative/create')
      await this.$store.dispatch('partner/fetchCampaignStructure', this.$route.params.campaignSlug)
      await this.$router.push({
        name: 'edit-creative',
        params: {
          campaignSlug: res.ad.ad_set.campaign.slug,
          groupSlug: res.ad.ad_set.slug,
          creativeSlug: res.ad.slug,
        },
      })
    },
    async update () {
      const hasAttachment = await this.$store.dispatch('creative/checkAttachments', this.locale)

      if (!hasAttachment) return

      await this.$store.dispatch('creative/update', this.locale)
      await this.$store.dispatch('partner/fetchCampaignStructure', this.$route.params.campaignSlug)
      await this.$parent.getCreative()
    },
  },
}
</script>

<style lang="scss" scoped>
.creative-actions {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  ::v-deep {
    .el-button {
      width: 150px;
    }
  }
}
</style>
