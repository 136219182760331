<template>
  <ElFormItem
    :label="locale.label"
    :prop="field"
    :error="errors[field]"
  >
    <ElInput
      :placeholder="locale.placeholder"
      v-model="model[field]"
      @input="formatInput"
    />
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    ElFormItem,
    ElInput,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    field: 'product_url',
  }),

  methods: {
    formatInput (value) {
      // this.$props.model.product_url = value.trim()
      this.$props.model.product_url = value.trim().replace(/[а-яА-Я]/g, '')
    },
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('form.data.fields.productUrl.label'),
      placeholder: $locale('form.data.fields.productUrl.placeholder'),
    }),
    errors: ({ $store }) => $store.getters['creative/errors'],
  },
}

</script>
