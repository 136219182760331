<template>
  <PartnerCampaignLayout>
    <div
      v-loading="!loaded"
      class="partner-creative"
    >
      <CreativeHeader :loaded="loaded" />

      <ElForm :model="creative">
        <!-- <CreativeStatus :model="creative" /> -->
        <CreativeTitle :model="creative" />
        <CreativeUploads />
        <CreativeAdTag :model="creative" />
        <CreativeSettings :model="creative" />
        <CreativeLabels :model="creative" />
      </ElForm>

      <CreativeActions />
    </div>
  </PartnerCampaignLayout>
</template>

<script>
import { Api } from 'lib/api/instance'

import ElForm from 'element/ElForm'
import loading from 'element/Loading'
import PartnerCampaignLayout from 'components/layouts/PartnerCampaignLayout/index.vue'
import CreativeActions from 'Partner/pages/PartnerCreative/components/CreativeActions'
import CreativeHeader from 'Partner/pages/PartnerCreative/components/CreativeHeader'
import CreativeLabels from 'Partner/pages/PartnerCreative/components/CreativeLabels'
import CreativeSettings from 'Partner/pages/PartnerCreative/components/CreativeSettings'
// import CreativeStatus from 'Partner/pages/PartnerCreative/components/CreativeStatus.vue'
import CreativeTitle from 'Partner/pages/PartnerCreative/components/CreativeTitle'
import CreativeUploads from 'Partner/pages/PartnerCreative/components/CreativeUploads'
import creativeStore from 'Partner/pages/PartnerCreative/store'
import groupStore from 'Partner/pages/PartnerGroup/BrandAwarenessGroup/store'

import CreativeAdTag from './components/CreativeAdTag.vue'

export default {
  components: {
    // CreativeStatus,
    PartnerCampaignLayout,
    CreativeHeader,
    CreativeTitle,
    CreativeUploads,
    CreativeSettings,
    CreativeLabels,
    CreativeActions,
    CreativeAdTag,
    ElForm,
  },

  directives: { loading },

  computed: {
    creative: ({ $store }) => $store.getters['creative/creative'],
    loading: ({ $store }) => $store.getters['creative/loading'],
    isEdit: ({ $route }) => $route.name === 'edit-creative',
    loaded: ({ loading, creative }) => !loading && creative.bootstrapped,
  },

  beforeCreate () {
    if (!this.$store.hasModule('group')) {
      this.$store.registerModule('group', groupStore)
    }

    if (!this.$store.hasModule('creative')) {
      this.$store.registerModule('creative', creativeStore)
    }
  },
  beforeDestroy () {
    this.$store.dispatch('creative/reset')
  },
  async created () {
    await this.$store.dispatch('dictionaries/fetchAwarenessCampaignDictionaries')
    await this.getGroup()
    await this.getCreative()

    document.title = `${this.creative.title || this.creative.slug} - Uplify`
  },
  // for change route from edit/create to create/edit
  beforeRouteLeave (to, from, next) {
    this.getCreative(to)
    next()
  },
  // for change same routes
  beforeRouteUpdate (to, from, next) {
    this.getCreative(to)
    next()
  },

  methods: {
    async getGroup (routeTo) {
      const routeName = routeTo?.name || this.$route.name
      const groupSlug = routeTo?.params.groupSlug || this.$route.params.groupSlug

      if (routeName === 'edit-group') {
        await this.$store.dispatch('group/fetch', groupSlug)
      }
      else if (routeName === 'create-group') {
        this.createNewGroup(routeTo)
      }
    },
    async getCreative (routeTo) {
      const routeName = routeTo?.name || this.$route.name
      const creativeSlug = routeTo?.params.creativeSlug || this.$route.params.creativeSlug

      if (routeName === 'edit-creative') {
        await this.$store.dispatch('creative/fetch', creativeSlug)
      }
      else if (routeName === 'create-creative') {
        this.createNewCreative(routeTo)
      }
    },

    async createNewCreative (routeTo) {
      const groupSlug = routeTo?.params.groupSlug || this.$route.params.groupSlug
      // временное решение получения формата через получение креатива
      const group = await this.fetchGroup(groupSlug)
      const data = {
        ad_set: {
          slug: group.slug,
          format: group.format.id,
          campaign: group.campaign,
        },
      }

      this.$store.commit('creative/toggleLoading', true) // need for usability
      setTimeout(() => {
        this.$store.commit('creative/setCreative', data)
        this.$store.commit('creative/toggleLoading', false)
      }, 300)
    },

    async fetchGroup (slug) {
      const res = await Api.get('partner/campaigns/ad-set/info', { slug })
      return res?.data
    },
  },
}
</script>

<style lang="scss" scoped>
.partner-creative {
  width: 100%;
  min-height: calc(100vh - #{$--layout-padding-vertical});
  padding-bottom: 115px;
  position: relative;
}
</style>
